import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Modal, Spinner, SpinnerSize, PivotItem
} from 'office-ui-fabric-react';
import moment from 'moment-timezone';
import { StickyContainer, Sticky } from 'react-sticky';
import styled from './WelcomeGuest.styled';
import { Text, LanguageContext } from '../../providers/LanguageProvider';
import { RootState } from '../../store/rootReducer';
import * as GuestAction from '../../store/guest/guestAction';
import * as GuestVisitSelector from '../../store/guest/guestSelector';
import {
  GuestPortalConstants, UpcomingVisits, PreviousVisits
} from '../../config/constants';
import { VisitInfo } from '../../store/guest/guestType';
import VisitsCard from '../../components/VisitsCard/VisitsCard';
import welcomeImgMobPath from '../../asset/images/welcomeguest-mobile.png';
import welcomeImgTabPath from '../../asset/images/welcomeguest-tablet.png';
import * as BuildingAction from '../../store/building/buildingAction';
import GuestDetail from '../../components/GuestDetail/GuestDetail';
import * as BuildingSelector from '../../store/building/buildingSelector';
import InfoBox from '../../components/InfoBox/InfoBox';
// import { CustomProperties } from '../../common/logging/logger';
import { GuestDetailLoggingProperties } from '../../common/logging/logPropertiesBuilder';
import logger from '../../common/logging/AILogger';
import {
  CommonLoggingConstants, SystemEventCodes, TargetEntity, EventCodes,
  GuestDetailLoggingConstants, BuildingLoggingConstants
} from '../../config/loggingCodes';
import { CustomProperties } from '../../common/logging/logger';
import Banner from '../../components/Banner/Banner';
import Config from '../../config/config';
import useDeviceType from '../../helpers/hooks/useDeviceType';
import { useId } from '@fluentui/react-hooks';

const WelcomeGuest: React.FC = () => {
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useDeviceType();

  const languageContext = useContext(LanguageContext);

  const isLangRTL = (languageContext.language.languageType
    === GuestPortalConstants.RtlLanguageType);

  useEffect(() => {
    const customProperties = GuestDetailLoggingProperties();
    logger.logSystemEvent(SystemEventCodes.SEV_011,
      SystemEventCodes.SEV_011,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`,
      TargetEntity.GUEST, undefined,
      undefined, undefined, customProperties);
      dispatch(GuestAction.fetchGuestVisitDetails());
  }, []);

  useEffect(() => {
    dispatch(GuestAction.updateGuestVisitDetailsSuccess(GuestPortalConstants.DefaultStatus));
  }, []);

  const {
    guestVisitDetail,
    isGuestDetailLoaded
  } = useSelector((state: RootState) => ({
    guestVisitDetail: GuestVisitSelector.guestSelector(state),
    isGuestDetailLoaded: GuestVisitSelector.guestDetailLoadedSelector(state)
  }), shallowEqual);

  const {
    buildingList,
    isBuildingConfigLoaded
  } = useSelector((state: RootState) => ({
    buildingList: BuildingSelector.buildingsSelector(state),
    isBuildingConfigLoaded: BuildingSelector.isBuildingConfigLoaded(state)
  }), shallowEqual);

  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const customProperties = GuestDetailLoggingProperties();
    logger.logSystemEvent(SystemEventCodes.SEV_010,
      SystemEventCodes.SEV_010,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`,
      TargetEntity.BUILDING_CONFIG, undefined,
      undefined, undefined, customProperties);

    dispatch(BuildingAction.fetchBuildingConfigs(guestVisitDetail
      && guestVisitDetail.visitList ? guestVisitDetail.visitList : []));
  }, [guestVisitDetail]);

  useEffect(() => {
    if (isGuestDetailLoaded === true && isBuildingConfigLoaded === true) {
      setShowLoader(false);
    }
  }, [isGuestDetailLoaded, isBuildingConfigLoaded]);

  const [pastVisits, setPastVisits] = useState<VisitInfo[]>([]);
  const [upcomingVisits, setUpcomingVisits] = useState<VisitInfo[]>([]);

  const [hasUpcomingVisits, setHasUpcomingVisits] = useState(false);
  const [hasPastVisits, setHasPastVisits] = useState(false);

  useEffect(() => {
    setPastVisits(guestVisitDetail.visitList
      .filter((visit) =>
        visit.isPastVisit).sort((a, b) =>
        moment(b.scheduledStartTime).diff(moment(a.scheduledStartTime))));
    setUpcomingVisits(
      guestVisitDetail.visitList
        .filter((visit) =>
          !visit.isPastVisit)
    );
  }, [guestVisitDetail]);

  useEffect(() => {
    setHasUpcomingVisits(!!upcomingVisits.length);
    setHasPastVisits(!!pastVisits.length);
    const customProperties: CustomProperties = [];
    customProperties[GuestDetailLoggingConstants.UpcomingVisitList] = upcomingVisits;
    customProperties[GuestDetailLoggingConstants.PastVisitList] = pastVisits;
    logger.logSystemEvent(SystemEventCodes.SEV_013, SystemEventCodes.SEV_013,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`, TargetEntity.VISIT_LIST,
      undefined, undefined, undefined, customProperties);
  }, [upcomingVisits, pastVisits]);

  let upcomingVisitBuildingId: string;

  const [isVehicleStateVisible, setIsVehicleStateVisible] = useState<boolean | undefined>(false);

  useEffect(() => {
    upcomingVisitBuildingId = (upcomingVisits?.sort((a, b) =>
      ((a.scheduledStartTime > b.scheduledStartTime) ? 1 : -1)))[0]?.buildingId;
    setIsVehicleStateVisible(buildingList.length > 0 ? (buildingList?.find((building) => building.buildingId === upcomingVisitBuildingId)?.gmsConfigs?.vehicleStateVisible) : false);
    const customProperties: CustomProperties = [];
    customProperties[GuestDetailLoggingConstants.IsVehicleStateVisible] = isVehicleStateVisible;
    customProperties[BuildingLoggingConstants.GuestVisitBuildingList] = buildingList;
    logger.logSystemEvent(SystemEventCodes.SEV_012, SystemEventCodes.SEV_012,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`, TargetEntity.BUILDING_CONFIG,
      undefined, undefined, undefined, customProperties);
  }, [buildingList]);

  const isNewGuest = !(guestVisitDetail.guest && guestVisitDetail.guest.emailId
    && guestVisitDetail.guest.firstName);

  const [isModalOpen, setIsModelOpen] = useState(false);

  const [visitsCardType, setVisitsCardType] = useState(UpcomingVisits);

  const OpenGuestEditModal = () => {
    const customProperties = GuestDetailLoggingProperties();
    logger.logEvent(EventCodes.EV_010,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`, customProperties);
    setIsModelOpen(true);
  };

  const DismissGuestEditModal = () => {
    logger.logEvent(EventCodes.EV_011,
      `${CommonLoggingConstants.ComponentName}WelcomeGuest`, undefined);
    setIsModelOpen(false);
  };

  const renderVisitCards = (): JSX.Element => {
    if(guestVisitDetail.visitList.length !== 0 && buildingList.length){
        return (
          <styled.StackStyle>
            <styled.VisitsContainer>
              {visitsCardType === UpcomingVisits
                && upcomingVisits.sort((a, b) => Number(b.buildingId) - Number(a.buildingId)).sort((a, b) =>
                  ((a.scheduledStartTime > b.scheduledStartTime) ? 1 : -1)).map((visit) => (
                  <VisitsCard
                      isPast={false}
                      visit={visit}
                      building={buildingList.find((b) => b.buildingId === visit.buildingId)}
                      key={visit.visitId}
                      numberOfVisits={upcomingVisits.filter(
                      (a) => a.isEditable && !buildingList.filter(
                        (b) => !b.gmsConfigs.parkingAvailable
                      ).map((b) => b.buildingId).includes(a.buildingId)
                    ).length}
                    />
                ))}
              {visitsCardType === PreviousVisits
                && pastVisits.map((visit) => (
                  <VisitsCard
                    isPast
                    visit={visit}
                    building={buildingList.find((b) => b.buildingId === visit.buildingId)}
                    key={visit.visitId}
                    numberOfVisits={PreviousVisits.length}
                  />
                ))}
            </styled.VisitsContainer>
          </styled.StackStyle>
        )}
        return <></>;
      };

  const titleId = useId('title');

  return (
    <div>
      {showLoader && <Spinner styles={styled.LoaderStyle} size={SpinnerSize.large} />}
      {!showLoader && (
        <div>
          <styled.Container>

            {isMobile
              && (
                <styled.WelcomeImage
                  alt={languageContext.dictionary.WelcomeMessage}
                  src={welcomeImgMobPath}
                />
              )}
            {isTablet && (
              <styled.WelcomeImage
                alt={languageContext.dictionary.WelcomeMessage}
                src={welcomeImgTabPath}
              />
            )}
            <styled.WelcomeGuestContainer isRTL={isLangRTL} isDesktop={!isMobile && !isTablet}>
              <styled.Title role="heading" aria-level={1}>
                {!isLangRTL && <Text tid="PreRegisterQRHeader" />}
                {" "}
                {isLangRTL && <span>!</span>}
                {isNewGuest && <Text tid="PreRegisterQRSubject" />}
                {!isNewGuest && (
                  <span>
                    {guestVisitDetail.guest.firstName}
                  </span>
                )}
                {!isLangRTL && <span>!</span>}
                {isLangRTL && <span><Text tid="PreRegisterQRHeader" /></span>}
              </styled.Title>
              <styled.SubTitle>
                {hasUpcomingVisits && hasPastVisits && <Text tid="HasUpcomingAndPastVisitsTitle" />}
                {hasUpcomingVisits && !hasPastVisits && (
                  <Text tid="HasOnlyUpcomingVisitsTitle" />
                )}
                {!hasUpcomingVisits && hasPastVisits && (
                  <Text tid="NoUpcomingVisitsTitle" />
                )}
                {!hasUpcomingVisits && !hasPastVisits && (
                  <Text tid="NoVisitsTitle" />
                )}
              </styled.SubTitle>
              <styled.WelcomeMessage>
                {(hasUpcomingVisits || (!hasUpcomingVisits && hasPastVisits
                  && (!guestVisitDetail.guest
                    || !guestVisitDetail.guest.firstName)))
                  && <Text tid="WelcomeMessage" />}
                {!hasUpcomingVisits && hasPastVisits && guestVisitDetail.guest
                  && guestVisitDetail.guest.firstName
                  && <Text tid="NoUpcomingVisitsWelcomeMessage" />}
                {!hasUpcomingVisits && !hasPastVisits
                  && <Text tid="NoVisitsWelcomeMessage" />}
              </styled.WelcomeMessage>

              <styled.ButtonContainer>
                {hasUpcomingVisits && (
                  <styled.Button
                    onClick={() => OpenGuestEditModal()}
                    aria-describedby="update-preregister"
                  >
                    <span id="update-preregister" hidden>{GuestPortalConstants.UpdateInfoDialogDescription}</span>
                    {isNewGuest && <Text tid="PreRegisterButtonText" />}
                    {!isNewGuest && <Text tid="UpdateRegisterButtonText" />}
                  </styled.Button>
                )}
              </styled.ButtonContainer>

            </styled.WelcomeGuestContainer>
            <Modal
              isOpen={isModalOpen}
              onDismiss={() => DismissGuestEditModal()}
              isBlocking={false}
              titleAriaId={titleId}
              scrollableContentClassName={styled.ContentStyles.scrollable}
            >
                <GuestDetail
                  isVehicleStateVisible={isVehicleStateVisible}
                  setIsModalOpen={setIsModelOpen}
                  isPage={false}
                  vehForm={false}
                  onSave={null}
                  titleId={titleId}
                  />
            </Modal>
          </styled.Container>
          {Config.SHOW_COVID_BANNER && (
            <styled.StackStyle>
              <Banner
                BannerText="COVIDBannerText"
                BannerLinkText="COVIDBannerLinkText"
                BannerLink={Config.CovidSurveyLink}
              />
            </styled.StackStyle>
          )}
          <>
            <styled.StackStyle>
              <styled.PivotContainer horizontalAlign="center">
                <styled.RTLResponsivePivot
                  onLinkClick={(item) => setVisitsCardType(
                    item?.props.itemKey ? item.props.itemKey : UpcomingVisits
                  )}
                  defaultSelectedKey={UpcomingVisits}
                  isRTL={isLangRTL}
                  headersOnly
                >
                  {isLangRTL
                    && (
                      <PivotItem
                        headerText={Text({ tid: 'PreviousVisits' })}
                        itemCount={pastVisits.length}
                        itemKey={PreviousVisits}
                        tabIndex={0}
                        ariaLabel={languageContext.dictionary.PreviousVisits + "," + pastVisits.length}
                      />
                    )}
                  <PivotItem
                    headerText={Text({ tid: 'UpcomingVisits' })}
                    itemCount={upcomingVisits.length}
                    itemKey={UpcomingVisits}
                  />
                  {!isLangRTL
                    && (
                      <PivotItem
                        headerText={Text({ tid: 'PreviousVisits' })}
                        itemCount={pastVisits.length}
                        itemKey={PreviousVisits}
                        tabIndex={0}
                        ariaLabel={languageContext.dictionary.PreviousVisits + "," + pastVisits.length}
                      />
                    )}
                </styled.RTLResponsivePivot>
              </styled.PivotContainer>
            </styled.StackStyle>

            {renderVisitCards()}

          </>
        </div>
      )}
    </div>
  );
};

export default WelcomeGuest;
