/**
 * Config for Non-Prod/PPE environment
 */
export default {
  // Base URL of APIM
  API_BASE_URL: "https://dwp-nonprod.azure-api.net/api/v1",
  //TODO keep the base URL same as the API_BASE_URL and remove VH_API_BASE_URL
  VH_API_BASE_URL: "https://dwp-nonprod.azure-api.net/v1",
  API_TIMEOUT: 60000,
  CDN_AVATAR_URL:
    "https://gmsassets-nonprod-djb2gjakhhf8bahm.b02.azurefd.net/avatar/",

  // Region Configuration
  REGION_CONFIG: {
    defaultRegion: "eu",
    validRegionList: ["us", "eu"],
  },

  // Health check
  CovidSurveyLink: "https://msrtw.powerappsportals.com/",

  // MSAL Adal auth configuration for US and EU region
  AUTH_CONFIG: {
    eu: {
      instance: "https://lobbyeu.b2clogin.com/tfp/",
      // tenant: 'microsoftlobby.onmicrosoft.com',
      tenant: "lobbyeu.onmicrosoft.com",
      signInPolicy: "b2c_1a_signup_signin",
      resetPolicy: "b2c_1a_passwordreset",
      // applicationId: 'c9a2502f-b9ca-404f-bd73-d6317c719ad9',
      applicationId: "4eefa405-fd11-419f-80cf-bcd0c3536691",
      cacheLocation: "sessionStorage",
      // https://microsoftlobby.onmicrosoft.com/c9a2502f-b9ca-404f-bd73-d6317c719ad9/demo.read',
      // scopes: ['https://lobbyguesteu.onmicrosoft.com/c9a2502f-b9ca-404f-bd73-d6317c719ad9/demo.read', 'https://lobbyguesteu.onmicrosoft.com/c9a2502f-b9ca-404f-bd73-d6317c719ad9/user_impersonation'],
      scopes: [
        "https://lobbyeu.onmicrosoft.com/guestportal/user_impersonation",
      ],
      redirectUri: "http://localhost:3000/eu",
      postLogoutRedirectUri: "http://localhost:3000/eu",
      validateAuthority: false,
      silentLoginOnly: false,
      navigateToLoginRequestUrl: false,
      webApiAcopes: [
        "https://lobbyeu.onmicrosoft.com/guestportal/user_impersonation",
      ],
      // webApiAcopes: ['https://microsoftlobby.onmicrosoft.com/c9a2502f-b9ca-404f-bd73-d6317c719ad9/demo.read', 'https://microsoftlobby.onmicrosoft.com/c9a2502f-b9ca-404f-bd73-d6317c719ad9/user_impersonation']
    },
    us: {
      instance: "https://lobby.b2clogin.com/tfp/",
      tenant: "lobby.onmicrosoft.com",
      signInPolicy: "b2c_1a_signup_signin",
      resetPolicy: "b2c_1a_passwordreset",
      applicationId: "59eb9d93-6d5f-40ac-830a-59b2d4bb8e99",
      cacheLocation: "sessionStorage",
      scopes: ["https://lobby.onmicrosoft.com/api/user_impersonation"],
      redirectUri: "http://localhost:3000/us",
      postLogoutRedirectUri: "http://localhost:3000/us",
      validateAuthority: false,
      silentLoginOnly: false,
      navigateToLoginRequestUrl: false,
      webApiAcopes: ["https://lobby.onmicrosoft.com/api/user_impersonation"],
      // webApiAcopes: ['https://lobby.onmicrosoft.com/guest/user_impersonation']
    },
  },
  // },

  // Telemetry configuration
  TELEMETRY_CONFIG: {
    environmentName: "Local",
    instrumentationKey: "355a3299-94ba-41c6-bcf9-e8d4fde842ed",
    serviceOffering: "End User Services Engineering",
    serviceLine: " Digital Workplace",
    service: "Arrive and Depart",
    componentName: "Guest Management System",
    componentId: "1263b4bb-89e2-41f4-a076-cda7dbd4658f",
    logLevel: 2, // 0 - off, 1: Critical only 2: Critical,warnings&all
    sessionExpirationTimeInMS: 3600000,
    enableAutoRouteTracking: true,
    enablePropertyObfuscation: false,
    logRequestBody: true,
  },
};
