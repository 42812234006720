/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable import/extensions */
import React, { useContext, useEffect, useState } from "react";
import {
  Stack,
  StackItem,
  IDropdown,
  IDropdownOption,
  Icon,
  Dropdown,
} from "office-ui-fabric-react";
import { useFormContext, Controller } from "react-hook-form";
import styled from "./VehicleDetail.styled";
import { Text, LanguageContext } from "../../../providers/LanguageProvider";
import {
  GuestPortalConstants,
  LicensePlateRegex,
  ModelRegex,
  MakeRegex,
} from "../../../config/constants";
import { VehicleDetailsType } from "../../../store/guest/guestType";
import {
  vehicleStatesProvinces,
  vehicleType,
} from "../../../store/guest/guestResource";

interface Props {
  isVehicleShow: boolean;
  isVehicleStateVisible: boolean | undefined;
  vehicleInfo?: VehicleDetailsType | null | undefined;
}

const vehicleTypeDropdownOptions: IDropdownOption[] = vehicleType.map(
  (type) => ({
    key: type.Text,
    text: type.Text,
    value: type.Text,
  })
);

const VehicleDetail: React.FC<Props> = ({
  isVehicleShow,
  isVehicleStateVisible,
  vehicleInfo,
}) => {
  const methods = useFormContext();

  const vehicleTypeRef = React.createRef<IDropdown>();

  const vehicleStateProvinceMasterList = vehicleStatesProvinces.map(
    (vehicleStateProvince) => ({
      key: vehicleStateProvince.StateDesc,
      text: vehicleStateProvince.StateDesc,
      value: vehicleStateProvince.StateDesc,
    })
  );

  const [selectedMake, setSelectedMake] = useState(
    vehicleInfo && vehicleInfo.make ? vehicleInfo.make : ""
  );
  const [selectedModel, setSelectedModel] = useState(
    vehicleInfo && vehicleInfo.model ? vehicleInfo.model : ""
  );
  const [registrationId, setRegistrationId] = useState(
    vehicleInfo && vehicleInfo.registrationId ? vehicleInfo.registrationId : ""
  );
  const [vehicleType, setVehicleType] = useState(
    vehicleInfo ? vehicleInfo.type : ""
  );
  const [selectedRegistrationState, setSelectedRegistrationState] = useState(
    vehicleInfo ? vehicleInfo.registrationState : ""
  );

  useEffect(() => {
    if (!isVehicleShow) {
      setSelectedMake(vehicleInfo && vehicleInfo.make ? vehicleInfo.make : "");
      setSelectedModel(
        vehicleInfo && vehicleInfo.model ? vehicleInfo.model : ""
      );
      setRegistrationId(
        vehicleInfo && vehicleInfo.registrationId
          ? vehicleInfo.registrationId
          : ""
      );
      setVehicleType(vehicleInfo ? vehicleInfo.type : "" ?? "");
      setSelectedRegistrationState(
        vehicleInfo && vehicleInfo.registrationState
          ? vehicleInfo.registrationState
          : ""
      );
      methods.setValue("vehicleInfo.licensePlate", vehicleInfo?.registrationId);
    }
  }, [isVehicleShow]);

  useEffect(() => {
    setSelectedMake(vehicleInfo && vehicleInfo.make ? vehicleInfo.make : "");
    setSelectedModel(vehicleInfo && vehicleInfo.model ? vehicleInfo.model : "");
    setRegistrationId(
      vehicleInfo && vehicleInfo.registrationId
        ? vehicleInfo.registrationId
        : ""
    );
    setVehicleType(vehicleInfo ? vehicleInfo.type : "" ?? "");
    setSelectedRegistrationState(
      vehicleInfo && vehicleInfo.registrationState
        ? vehicleInfo.registrationState
        : ""
    );
    methods.reset({
      vehicleInfo: {
        make: vehicleInfo && vehicleInfo.make ? vehicleInfo.make : "",
        model: vehicleInfo && vehicleInfo.model ? vehicleInfo.model : "",
        registrationId:
          vehicleInfo && vehicleInfo.registrationId
            ? vehicleInfo.registrationId
            : "",
        type: vehicleInfo ? vehicleInfo.type : "" ?? "",
        registrationState:
          vehicleInfo && vehicleInfo.registrationState
            ? vehicleInfo.registrationState
            : "",
      },
    } as any);
  }, [vehicleInfo]);

  //To autofocus on vehicle type dropdown when it is the only error
  useEffect(() => {
    const hasOnlyTypeError =
      methods.errors.vehicleInfo &&
      Object.keys(methods.errors.vehicleInfo).length === 1 &&
      methods.errors.vehicleInfo["type"];

    if (hasOnlyTypeError) {
      vehicleTypeRef.current!.focus(false);
    }
  }, [methods.errors.vehicleInfo]);

  const languageContext = useContext(LanguageContext);

  const languageType = languageContext.language
    ? languageContext.language.languageType
    : GuestPortalConstants.LtrLanguageType;
  const isLangRTL = languageType === GuestPortalConstants.RtlLanguageType;

  /**
   * Applies placeholder styles on placeholder render
   * @param event
   */
  const onRenderPlaceHolder = (event): JSX.Element => (
    <div style={{ width: "100%" }}>
      {isLangRTL && (
        <Icon
          style={{ float: "left" }}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
      <styled.DropdownPlaceholderStyle isRTL={isLangRTL}>
        <Text tid={event.placeholder} />
      </styled.DropdownPlaceholderStyle>
    </div>
  );

  /**
   * Applies caretdown styles on caretdown render
   * @param event
   */
  const onRenderCaretDown = (event): JSX.Element => (
    <span>
      {!isLangRTL && <Icon iconName="ChevronDown" aria-hidden="true" />}
    </span>
  );

  /**
   * Applies title styles on title render
   * @param event
   */
  const onRenderTitle = (options?: IDropdownOption[]): JSX.Element => (
    <div style={{ width: "100%" }}>
      {isLangRTL && (
        <Icon
          style={{ float: "left" }}
          iconName="ChevronDown"
          aria-hidden="true"
        />
      )}
      {options && options.length ? options[0].text : ""}
    </div>
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChange = (e) => {
    setRegistrationId(e.currentTarget.value);
    methods.setValue("vehicleInfo.licensePlate", e.currentTarget.value);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onModelChange = (e) => {
    setSelectedModel(e.currentTarget.value);
    methods.setValue("vehicleInfo.model", e.currentTarget.value);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onMakeChange = (e) => {
    setSelectedMake(e.currentTarget.value);
    methods.setValue("vehicleInfo.make", e.currentTarget.value);
  };

  const errorFragment = (isRTL, message, errorId) => {
    if (isVehicleShow) {
      return (
        <styled.errorFragment
          id={errorId}
          aria-live="assertive"
          role="alert"
          isRTL={isRTL}
        >
          <Text tid={message} />
        </styled.errorFragment>
      );
    }
    return null;
  };

  return (
    <Stack styles={styled.VehicleStack}>
      {/* {showLoader && <Spinner styles={styled.LoaderStyle} size={SpinnerSize.large} />} */}
      {/* {!showLoader && ( */}
      <Stack>
        <styled.MakeModelStack>
          {!isLangRTL && ( // MAKE
            <StackItem styles={styled.VehicleDetailField}>
              <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
                <Text tid="Make" />
              </styled.FieldLabel>
              <styled.LongFieldText
                isRTL={isLangRTL}
                disabled={!isVehicleShow}
                name="vehicleInfo.make"
                onChange={onMakeChange}
                aria-describedby={
                  isVehicleShow ? "error-vehicle-make" : undefined
                }
                ref={methods.register({
                  required: isVehicleShow,
                  maxLength: 50,
                  pattern: MakeRegex,
                })}
                defaultValue={selectedMake}
                placeholder={languageContext.dictionary.MakePlaceholder}
                aria-label={languageContext.dictionary.MakePlaceholder}
              />
              {methods.errors.vehicleInfo &&
                ((methods.errors.vehicleInfo as any).make as any)?.type ===
                  "required" &&
                errorFragment(isLangRTL, "FieldRequired", "error-vehicle-make")}
              {methods.errors.vehicleInfo &&
                (((methods.errors.vehicleInfo as any).make as any)?.type ===
                  "pattern" ||
                  ((methods.errors.vehicleInfo as any).make as any)?.type ===
                    "maxLength") &&
                errorFragment(isLangRTL, "FieldInvalid", "error-vehicle-make")}
            </StackItem>
          )}
          <styled.ModelStackItemStyle isRTL={isLangRTL}>
            <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
              <Text tid="Model" />
            </styled.FieldLabel>
            <styled.LongFieldText
              isRTL={isLangRTL}
              disabled={!isVehicleShow}
              name="vehicleInfo.model"
              aria-describedby={
                isVehicleShow ? "error-vehicle-model" : undefined
              }
              required
              onChange={onModelChange}
              ref={methods.register({
                required: isVehicleShow,
                maxLength: 50,
                pattern: ModelRegex,
              })}
              defaultValue={selectedModel}
              placeholder={languageContext.dictionary.ModelPlaceholder}
              aria-label={languageContext.dictionary.ModelPlaceholder}
            />
            {methods.errors.vehicleInfo &&
              ((methods.errors.vehicleInfo as any).model as any)?.type ===
                "required" &&
              errorFragment(isLangRTL, "FieldRequired", "error-vehicle-model")}
            {methods.errors.vehicleInfo &&
              (((methods.errors.vehicleInfo as any).model as any)?.type ===
                "pattern" ||
                ((methods.errors.vehicleInfo as any).model as any)?.type ===
                  "maxLength") &&
              errorFragment(isLangRTL, "FieldInvalid", "error-vehicle-model")}
          </styled.ModelStackItemStyle>
          {isLangRTL && ( // MAKE
            <StackItem styles={styled.VehicleDetailField}>
              <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
                <Text tid="Make" />
              </styled.FieldLabel>
              <styled.LongFieldText
                isRTL={isLangRTL}
                disabled={!isVehicleShow}
                name="vehicleInfo.make"
                aria-describedby={
                  isVehicleShow ? "error-vehicle-make" : undefined
                }
                onChange={onMakeChange}
                ref={methods.register({
                  required: isVehicleShow,
                  maxLength: 50,
                  pattern: MakeRegex,
                })}
                defaultValue={selectedMake}
                placeholder={languageContext.dictionary.MakePlaceholder}
                aria-label={languageContext.dictionary.MakePlaceholder}
              />
              {methods.errors.vehicleInfo &&
                ((methods.errors.vehicleInfo as any).make as any)?.type ===
                  "required" &&
                errorFragment(isLangRTL, "FieldRequired", "error-vehicle-make")}

              {methods.errors.vehicleInfo &&
                (((methods.errors.vehicleInfo as any).make as any)?.type ===
                  "pattern" ||
                  ((methods.errors.vehicleInfo as any).make as any)?.type ===
                    "maxLength") &&
                errorFragment(isLangRTL, "FieldInvalid", "error-vehicle-make")}
            </StackItem>
          )}
        </styled.MakeModelStack>
        <Stack>
          <StackItem styles={styled.LicensePlateField}>
            <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
              <Text tid="LicensePlate" />
            </styled.FieldLabel>
            <styled.LongFieldText
              isRTL={isLangRTL}
              disabled={!isVehicleShow}
              name="vehicleInfo.licensePlate"
              aria-describedby={
                isVehicleShow ? "error-vehicle-licensePlate" : undefined
              }
              onChange={onChange}
              ref={methods.register({
                required: isVehicleShow,
                maxLength: 20,
                pattern: LicensePlateRegex,
              })}
              defaultValue={registrationId}
              placeholder={languageContext.dictionary.LicensePlatePlaceholder}
              aria-label={languageContext.dictionary.LicensePlatePlaceholder}
            />
            {methods.errors.vehicleInfo &&
              ((methods.errors.vehicleInfo as any).licensePlate as any)
                ?.type === "required" &&
              errorFragment(
                isLangRTL,
                "FieldRequired",
                "error-vehicle-licensePlate"
              )}
            {methods.errors.vehicleInfo &&
              (((methods.errors.vehicleInfo as any).licensePlate as any)
                ?.type === "pattern" ||
                ((methods.errors.vehicleInfo as any).licensePlate as any)
                  ?.type === "maxLength") &&
              errorFragment(
                isLangRTL,
                "FieldInvalid",
                "error-vehicle-licensePlate"
              )}
          </StackItem>
        </Stack>
        <Stack>
          <StackItem styles={styled.VehicleStateField}>
            <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
              <Text tid="TypeLabel" />
            </styled.FieldLabel>
            <styled.VehicleDropdownContainer>
              <Controller
                name="vehicleInfo.type"
                rules={{
                  required: isVehicleShow,
                }}
                as={
                  <Dropdown
                    componentRef={vehicleTypeRef}
                    disabled={!isVehicleShow}
                    options={vehicleTypeDropdownOptions}
                    aria-describedby={
                      isVehicleShow ? "error-vehicle-type" : undefined
                    }
                    styles={styled.VehicleDropdownStyles(isLangRTL)}
                    placeholder="TypePlaceholder"
                    onRenderPlaceholder={onRenderPlaceHolder}
                    onRenderTitle={onRenderTitle}
                    onRenderCaretDown={onRenderCaretDown}
                    defaultSelectedKey={vehicleType}
                    aria-label={languageContext.dictionary.TypePlaceholder}
                  />
                }
                control={methods.control}
                onChange={([event, value]) => {
                  setVehicleType(value.key);
                  methods.setValue("vehicleInfo.type", value.key);
                  console.log(
                    "vehicle type",
                    value.key,
                    methods.getValues(),
                    methods
                  );
                  return value.value;
                }}
              />
            </styled.VehicleDropdownContainer>
            {methods.errors.vehicleInfo &&
              isVehicleShow &&
              ((methods.errors.vehicleInfo as any).type as any)?.type ===
                "required" &&
              errorFragment(isLangRTL, "FieldRequired", "error-vehicle-type")}
          </StackItem>
        </Stack>
        {isVehicleStateVisible && (
          <Stack>
            <StackItem styles={styled.VehicleStateField}>
              <styled.FieldLabel isRTL={isLangRTL} disabled={!isVehicleShow}>
                <Text tid="StateLabel" />
              </styled.FieldLabel>
              <styled.VehicleDropdownContainer>
                <Controller
                  name="vehicleInfo.registrationState"
                  rules={{
                    required: isVehicleShow,
                  }}
                  as={
                    <Dropdown
                      disabled={!isVehicleShow}
                      options={vehicleStateProvinceMasterList}
                      errorMessage={
                        isVehicleShow ? "Please select a state" : ""
                      }
                      aria-describedby={
                        isVehicleShow
                          ? "error-vehicle-registrationState"
                          : undefined
                      }
                      styles={styled.VehicleDropdownStyles(isLangRTL)}
                      placeholder="StatePlaceholder"
                      onRenderPlaceholder={onRenderPlaceHolder}
                      onRenderTitle={onRenderTitle}
                      onRenderCaretDown={onRenderCaretDown}
                      defaultSelectedKey={selectedRegistrationState}
                      aria-label={languageContext.dictionary.StatePlaceholder}
                    />
                  }
                  control={methods.control}
                  onChange={([event, value]) => {
                    setSelectedRegistrationState(value.key);
                    methods.setValue(
                      "vehicleInfo.registrationState",
                      value.key
                    );
                    return value.value;
                  }}
                />
              </styled.VehicleDropdownContainer>
              {methods.errors.vehicleInfo &&
                isVehicleShow &&
                ((methods.errors.vehicleInfo as any).registrationState as any)
                  ?.type === "required" &&
                errorFragment(
                  isLangRTL,
                  "FieldRequired",
                  "error-vehicle-registrationState"
                )}
            </StackItem>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default VehicleDetail;
